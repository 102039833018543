<div class="company-card disable-dimensions-shrink" [ngStyle]="{'border-color': borderColor+' !important'}">
  <div class="logo-wrapper disable-dimensions-shrink" (click)="goToCompanyPage()">
    <app-image-square class="disable-shrink" [imageSrc]="companyLogoUrl" [namedEntity]="company"
      style="min-width: 44px;" width="44px" />
    <div class="display-none-on-shrink flex-grow-1 overflow-hidden">
      <div class="d-flex align-items-center gap-2 fw-500" [ngClass]="{'f-neutral-400': !company}">
        <span style="white-space: nowrap; text-overflow: ellipsis;">{{companyName}}</span>
        <app-company-verification-badge *ngIf="company" [company]="company" [showOnActive]="true" />
      </div>
      <div class="d-flex gap-2">
        <app-company-status class="disable-dimensions-shrink" [company]="company" />
        <app-badge-old class="package-name" [matTooltip]="packageTooltip"
          (click)="goToPricingPage();$event.stopPropagation()">
          {{packageName}}
        </app-badge-old>
      </div>
    </div>
  </div>
  <div class="margin-childs-vertical-8 mg-t-12" *ngIf="statusDescription">
    <div class="f-12">
      <div class="f-neutral-500 mg-b-4">{{statusDescription}}
        <span *ngIf="ctaText" class="f-12 fw-500 f-underline cursor-pointer text-nowrap" (click)="ctaClick(ctaUrl)">
          {{ctaText}}
        </span>
      </div>
    </div>
  </div>
</div>